<template>

  <div style="overflow-y: auto; max-height: 100vh" v-if="profile.status === 'Available'">
    <v-container>
      <v-row align="center">
        <v-col>
          <h1 class="comp-title">Team Challenges</h1>
        </v-col>
        <v-col class="justify-end d-flex">
          <v-btn
        color="#084468"
        @click="createCompetition"
        class="create-btn"
        width="150"
        ><v-icon color="#fff" style="margin-top: 2px" size="18">mdi-plus</v-icon> Create</v-btn
          >
          <v-btn
        color="#084468"
        @click="history"
        class="hist-btn"
        width="50"
        ><v-icon color="#fff" style="margin-top: 2px" size="18">mdi-history</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-container >
      <v-row>
        <v-col
          v-for="competition in competitions"
          :key="competition._id"
          cols="12"
        >
          <v-card v-if="competitions.length > 0 && profile._id === competition.createdBy" @click="detailedView(competition)">
            <v-card-text class="flex-row mt-3 d-flex" style="width: 100%">
              <div>
                <v-img
                  :src="require('@/assets/svg/competionCard.svg')"
                  width="50"
                  height="60"
                  contain
                />
              </div>
              <div class="mx-3 d-flex flex-column" style="flex-grow: 1">
                <span style="font-size: 18px; font-family: 'Poppins-SemiBold'">
                  {{ competition.title.toUpperCase() }}
                </span>
                <span
                  style="
                  color: #0a5b8a;
                  font-size: 14px;
                  font-family: 'Poppins-Regular';
                  "
                >
                    {{ competition.showFullDescription
        ? competition.description
        : competition.description.slice(0, 100) + (competition.description.length > 100 ? '...' : '') }}
                </span>
                <v-btn
                  v-if="competition.description.length > 100"
                  text
                  small
                  color="#0a5b8a"
                  @click.stop="toggleDescription(competition)"
                  class="toggle-description-btn"
                >
                  {{ competition.showFullDescription ? 'Show Less' : 'Show More' }}
                </v-btn>
              </div>

              <div style="flex-shrink: 0">
                <v-tooltip bottom v-if="new Date(competition.end_date) > new Date()">
                  <template v-slot:activator="{ on, attrs }">
                  <v-btn icon
                    style="border-radius: 1px;
                      border-color: #0a5b8a;
                      border-style: solid;
                      border-radius: 36px;"
                    small
                    @click.stop="onEdit(competition)"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon color="#0a5b8a" style="margin-top: 2px" size="18">mdi-pencil</v-icon>
                  </v-btn>
                  </template>
                  Edit Competition
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                      style="border-radius: 1px;
                             border-color: #0a5b8a;
                             border-style: solid;
                             border-radius: 36px;"
                      small
                      class="ml-2"
                      @click.stop="onDelete(competition)"
                      v-bind="attrs"
                      v-on="on">
                      <v-icon color="#0a5b8a" style="margin-top: 2px" size="18">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  Delete Competition
                </v-tooltip>
              </div>
            </v-card-text>
            <v-row>
              <v-col cols="6">
                <v-card-subtitle>
                  <span class="heading"> Start Date : </span>
                  {{ new Date(competition.start_date).toLocaleDateString() }}
                </v-card-subtitle>
                    <v-card-subtitle>
                      <span class="heading"> End Date: </span>
                        {{ new Date(competition.end_date).toLocaleDateString() }}
                    </v-card-subtitle>
                       <v-card-subtitle>
              <span class="heading"> Competition Status: </span>
              {{ competition.competition_status }}
            </v-card-subtitle>
              </v-col>
              <v-col cols="6" v-if="competition.highest_scoring_team != null">
                <v-card-subtitle>
                  <span class="heading"> High Scored Teams: </span>
                </v-card-subtitle>
                <v-card-subtitle >
                  <div class="agencyTitle"> Agency Name : 
                    <span class="agencyName">{{ (competition.highest_scoring_team.agency_name)?.toUpperCase() }}</span>
                  </div>
                <div class="agencyTitle"> Points : 
                    <span class="agencyName">{{competition.highest_scoring_team.total_points}}</span>
                </div>
                </v-card-subtitle>
               <v-card-subtitle>
                  <div class="memberTitle"> Member Name : 
                    <span class="memberName">{{ (competition.highest_scoring_team.highest_scoring_member.first_name + ' '+ competition.highest_scoring_team.highest_scoring_member.last_name)?.toUpperCase() }}</span>
                  </div>
                <div class="memberTitle"> Points : 
                    <span class="memberName">{{competition.highest_scoring_team.highest_scoring_member.points}}</span>
                </div>
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-card v-if="competitions.length === 0" class="mt-6">
        <v-card-text> {{errorMessage}} </v-card-text>
      <v-skeleton-loader
        v-if="loading"
        type="table"
        class="mt-6"
        :loading="!competitions.length"
        height="100px"
      >
      </v-skeleton-loader>
      </v-card>
    </v-container>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    <create-competitions
      :dialog="createDialog"
      :onClose="onCloseDialog"
      v-if="createDialog"
    />
    <edit-competition
      :dialog="editDialog"
      :onClose="onCloseDialog"
      :respectiveCompetition="respectiveCompetition"
      v-if="editDialog"/>
      <delete-competition
      :dialog="deleteDialog"
      :onClose="onCloseDialog"
      :respectiveCompetition="respectiveCompetition"
      v-if="deleteDialog"/>
  </div>
  <h1 v-else-if="profile.status === 'Pending' || profile.status === 'Rejected'" class="approve-warn">Please wait for admin approval/confirmation</h1>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CreateCompetitions from "./CreateCompetitons.vue";
import DeleteCompetition from './DeleteCompetition.vue';
import EditCompetition from './EditCompetition.vue';
export default {
  components: {
    CreateCompetitions,
    EditCompetition,
    DeleteCompetition,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      competitions: [],
      notifications: [],
      errorNotifications: [],
      createDialog: false,
      editDialog: false,
      deleteDialog: false,
      respectiveCompetition: null,
      highScoredteams: null,
    };
  },
  methods: {
    ...mapActions({
      fetchEachCompetitions: "competition/fetchEachCompetitions",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    createCompetition() {
      this.createDialog = true;
    },
    onCloseDialog() {
      this.createDialog = false;
      this.editDialog = false;
      this.deleteDialog = false;
      this.loadCompetitions();
    },
    history() {
      this.$router.push({ name: "history" });
    },
    detailedView(competition) {
      this.$router.push({ name: "detailed-view", params: { id: competition.competition_id } });
    },
    onEdit(i){
      this.respectiveCompetition = i;
      this.editDialog = true;
    },
    onDelete(i){
      this.respectiveCompetition = i;
      this.deleteDialog = true;
    },
    async loadCompetitions() {
      try {
        this.loading = true;
        const response = await this.fetchEachCompetitions();
        this.competitions = response.map(competition => {
          return {
            ...competition,
            showFullDescription: false, // Explicitly add the property for reactivity
          };
        });
        response.forEach((competition) => {
          this.highScoredteams = competition.teams;
        });
      } catch (error) {
        this.showErrorMessage(
          error.message || "An error occurred while loading competitions."
        );
      } finally {
        this.loading = false;
      }
    },
    toggleDescription(competition) {
      competition.showFullDescription = !competition.showFullDescription;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  mounted() {
    this.loadCompetitions();
  },
};
</script>

<style scoped>
.comp-title {
  font-size: 30px;
  color: #fff;
}
.create-btn {
  color: #fff;
  margin: 20px 20px 0px 30%;
}
.hist-btn {
  color: #fff;
  margin: 20px 20px 0px 0%;
}

.heading {
  color: rgb(78, 78, 78);
  font-size: 1rem;
  font-weight: 500;
}
.competition-description {
  margin-bottom: 10px;
}

.toggle-description-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-size: 14px;
}

.toggle-description-btn:hover {
  color: #0056b3;
}

.agencyName {
  font-size: 14px;
  color: green;
  font-weight: 400;
}
.agencyTitle {
  font-size: 1rem;
  color: #000000;
  font-weight: 500;
}
.memberTitle {
    font-size: 1rem;
  color: #000000;
  font-weight: 500;
}
.memberName {
  font-size: 14px;
  color: green;
  font-weight: 400;
}
.approve-warn{
    font-size: 30px;
  padding: 80% 28%;
  color: #fff;
}
</style>